import React from "react";
import { Box } from "@mui/material";
import ClassView from "./ClassView";
import Time from "../../../components/Time";
import replaceById from "../../../hooks/replaceById";
import mut from "../../../hooks/mut";

export default function ClassesOnDay(props) {
  const { day, setClasses } = props;
  let classes = props.classes.filter(c => c.classDays.find(cd => cd.day === day) && c.location === props.location._id);
  const starttimes = []
  for (let htClass of classes) {
    let cd = htClass.classDays.find(c => c.day === day)
    if(!starttimes.includes(cd.startTime.substring(0,5))) starttimes.push(cd.startTime.substring(0,5))
  }
  const [dragOver,setDragOver] = React.useState("")
   const handleDragOver = (e,time) => {
     e.preventDefault();
     setDragOver(time)// Necessary to allow a drop
   };

   const handleDrop = async (e, day, startTime) => {
     e.preventDefault();

     let d = JSON.parse(e.dataTransfer.getData("object"));
     d.classDay.day = day;
     d.classDay.startTime = startTime;
     console.log(d);
     let response = await mut(
       "post",
       `/classes/${d.classId}/update-classday/${d.classDay._id}`,
       d.classDay
     );
     replaceById(setClasses, response);
       setDragOver("");
   };

  return (
    <div>
      {starttimes.sort().map((st) => (
        <Box
          key={"startTime" + st}
          sx={{
            background:st===dragOver ? "rgba(0,0,0,0.2)" : "white",
          }}
          onDrop={(e) => handleDrop(e, day, st)}
          onDragOver={(e) => handleDragOver(e, st)}
          onDragLeave={(e) => handleDragOver(e, "")}
        >
          <div
            style={{
              textAlign: "center",
              padding: "0px",
              paddingTop: "5px",
              fontSize: "12px",

              margin: 0,
            }}
          >
            <Time time={st} />
          </div>
          {classes
            .filter((c) =>
              c.classDays.find(
                (cd) => cd.day === day && cd.startTime.substring(0, 5) === st
              )
            )
            .map((cl) => (
              <div key={cl._id}>
                <ClassView
                  htclass={cl}
                  day={day}
                  {...props}
                />
              </div>
            ))}
        </Box>
      ))}
    </div>
  );
}
