import React, { useContext } from "react";
import { StaffContext } from "../../../../../Context";
import useDBO from "../../../hooks/useDBO";
import Staff from "../../../components/Staff";
import { startOfWeek } from "date-fns";



export default function PrintTimetable({ classes, startTimes, locations }) {
    const dt = startOfWeek(new Date(), { weekStartsOn: 1 })

    let foreignTeacher = useDBO(`/ft-timetable-history/${dt.tDate()}`)
    
    if (foreignTeacher.loading) return <></>
    let shifts = [];
    for (const teacher of foreignTeacher.get) {
        shifts = [...shifts, ...teacher.shifts.filter(s=>s.day===new Date().getDay()).map(s=>({...s, teacher: teacher._id}))];
    }
    

    return (
      <div>
        {startTimes.map((st) => (
          <ClassesAtTime
            time={st}
            classes={classes.filter((c) =>
              c.makeUpClass || c.extraClass
                ? c.startTime.substring(0, 5) === st
                : c.classDay.startTime.substring(0, 5) === st
            )}
            shifts={shifts}
          />
        ))}{" "}
      </div>
    );
}

function ClassesAtTime({ time, classes, shifts }) {
    
   const {findStaff} = useContext(StaffContext)
    return <div>
        <table className="table-sm" style={{ width: "100%" }}>
            <tr>
         <th colSpan={5} style={{textAlign:"center", background:"#888", color: "white"}}>
               {time}
                </th>
            </tr>
            <tr>
                <th style={{width: "15%"}}>Name</th>
                <th  style={{width: "15%"}}>Teacher</th>
                <th  style={{width: "15%"}}>Room</th>
                <th style={{ width: "15%" }}>Extra teachers</th>
                 <th  style={{width: "15%"}}>Notes</th>
            </tr>
            {classes.map(c => <tr>
                <td  style={{width: "15%"}}>{getName(c)}</td>
                <td  style={{width: "15%"}}>{getTeacher(c,findStaff)}</td>
                <td  style={{width: "15%"}}>{getRoom(c)}</td>
                <td  style={{ width: "15%" }}><GetFT shifts={shifts} htClass={c} /></td>
                <td  style={{width: "40%"}}>{c.lesson && c.lesson.adminNotes ? c.lesson.adminNotes : ""}</td>
            </tr>)}    
        </table>
        </div>
}

function GetFT({ shifts, htClass })
{
    const shift = shifts.find(s => s.name === htClass.name)
    if(!shift) return <></>
    return (<><Staff id={shift.teacher} /> {shift.startTime}-{shift.endTime}</>)
}

function getRoom(htClass) {
    if (htClass.makeUpClass || htClass.extraClass) return htClass.room || "No room"
    return htClass.classDay.room || "No room"
}

function getName(htClass) {
    if (htClass.makeUpClass) return "MUC: " + htClass.level
    if (htClass.extraClass) return "Extra"
    return htClass.name
}

function getTeacher(htClass, findStaff) {
    if (htClass.lesson) {
        return findStaff(htClass.lesson.teacher).name
    }
    if (htClass.teacher) {
        return findStaff(htClass.teacher).name
    }
    return "No teacher"
}
