import React, { useContext } from "react";
import { net } from "../../../../database";
import { TextField } from "@mui/material";
import ShowTags from "./ShowTags";

import { StaffContext, UserContext } from "../../../../Context";
export default function ChatInput(props) {
  //const {l,language} = props;

  const { message, messages } = props;
  const { staff } = useContext(StaffContext);
  const {user} = useContext(UserContext)
  const [chat, setChat] = React.useState("");
  const [showAt, setShowAt] = React.useState(false);
  const [staffFilter, setStaffFilter] = React.useState("");
  const [selected, setSelected] = React.useState(0);

  const sendChat = (e) => {
    if (e.keyCode === 13 && chat !== "") {
      net
        .post(`/message/${message._id}/chat`, {
          user: user._id,
          message: chat,
        })
        .then((result) => {
       
          messages.update(result);
        });
      setChat("");
    } else {
      let words = chat.split(" ");
      let lastWord = words[words.length - 1];

      if (lastWord.length > 0 && lastWord.substring(0, 1) === "@") {
        setStaffFilter(lastWord.replace("@", ""));
        setShowAt(true);
      }
    }
  };
  let st = staff.filter(
    (s) =>
      staffFilter.length > 0 &&
      s.name.toLowerCase().includes(staffFilter.toLowerCase())
  );
  const doTab = (e) => {
    if (e.keyCode === 9) {
      e.preventDefault();
      net
        .post(`/message/${message._id}/add-user`, {
          newPerson: st[selected]._id,
        })
        .then((res) => addUser(res, st[selected]));
    }
    if (e.keyCode === 38) {
      e.preventDefault();
      if (selected > 0) setSelected(selected - 1);
    }
    if (e.keyCode === 40) {
      e.preventDefault();
      setSelected(selected + 1);
    }
  };

  const addUser = (res, user) => {
    let position = chat.lastIndexOf("@");
    setShowAt(false);
    setChat(chat.substring(0, position + 1) + user.name);
    messages.set(messages.map((m) => (m._id === res._id ? res : m)));
  };

  return (
    <div>
      {showAt ? (
        <ShowTags
          messages={messages}
          setMessages={messages.set}
          message={message}
          addUser={addUser}
          selected={selected}
          staff={st}
        />
      ) : (
        ""
      )}
      <TextField
        sx={{ background: "White" }}
        size="small"
        className="chat-input"
        fullWidth
        value={chat}
        onKeyUp={(e) => sendChat(e)}
        onKeyDown={(e) => doTab(e)}
        onChange={(e) => setChat(e.target.value)}
        placeholder="@, write a message..."
      />
      {/*  <div
        className="chat-input"
        contentEditable
        onKeyUp={(e) => sendChat(e)}
        onChange={(e) => setChat(e.innerText)}
      >
        {parse(chat)}
      </div> */}
    </div>
  );
}
