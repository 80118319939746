import React, { useState } from "react";
import useDBO from "../../../hooks/useDBO";
import Loading from "../../../components/Loading";
import Error from "../../../components/Error";
import Lang from "../../../components/Lang";
import VND from "../../../components/VND";
import Loca from "../../../components/Loca";
import Staff from "../../../components/Staff";
import { format } from "date-fns";
import AddButton from "../../../components/AddButton";
import WithdrawDialog from "./WithdrawDialog";


export default function CashWithdrawals({date}) {


const withdrawals = useDBO(`/cashwithdrawals?tDate=${date}`)
    
const [open, setOpen] = useState(false);


if (withdrawals.loading) return <Loading />;
if (withdrawals.error) return <Error />;


    return (
      <div className="money-outline">
        <div
          className="money-subtitle"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div>
            <Lang w="cashWithdrawals" />
          </div>
          <AddButton
            tooltip={<Lang w="withdrawCashToTheOwner" />}
            action={() => setOpen(true)}
          />
        </div>
        <table className="withdraw-table">
          <tr>
            <th className="withdraw-table-heading">
              <Lang w="amount" />
            </th>
            <th className="withdraw-table-heading">
              <Lang w="float" />
            </th>
            <th className="withdraw-table-heading">
              <Lang w="location" />
            </th>
            <th className="withdraw-table-heading">
              <Lang w="cashier" />
            </th>
            <th className="withdraw-table-heading">
              <Lang w="time" />
            </th>
          </tr>
          {withdrawals.map((w) => (
            <tr key={w._id}>
              <td className="withdraw-table-cell">
                <VND value={w.amount} />
              </td>
              <td className="withdraw-table-cell">
                <VND value={w.float} />
              </td>
              <td className="withdraw-table-cell">
                <Loca id={w.location} />
              </td>
              <td className="withdraw-table-cell">
                <Staff id={w.cashier} />
              </td>
              <td className="withdraw-table-cell">
                {format(new Date(w.createdAt), "h:mm aaa")}
              </td>
            </tr>
          ))}
        </table>
        <WithdrawDialog
          open={open}
          setOpen={setOpen}
          withdrawals={withdrawals}
        />
      </div>
    );
}