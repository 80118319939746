import { useState } from "react";
import Loading from "../../components/Loading";
import Error from "../../components/Error";
import { Button } from "@mui/material";
import mut from "../../hooks/mut";
import replaceById from "../../hooks/replaceById";
import { format } from "date-fns";
import useDBO from "../../hooks/useDBO";
import SelectFromCenter from "../../components/SelectFromCenter";
import VND from "../../components/VND";
import AddButton from "../../components/AddButton";
import NewStaffDialog from "./NewStaffDialog";
import Lang from "../../components/Lang";


export default function Humanresources(props) {

  const [status, setStatus] = useState(0)
  const [filter, setFilter] = useState("")
  const data = useDBO(`/users-hr/${status}`);
  const [newStaff,setNewStaff] = useState(false)


if (data.error) return <Error />;

  return (
    <div>
      <div style={{ display: "flex", gap: 10 }}>
        <Button
          {...(status === 0 && {
            color: "success",
          })}
          onClick={() => setStatus(0)}
        >
          Current ({data.get && data.get.length})
        </Button>
        <Button
          {...(status === 1 && {
            color: "success",
          })}
          onClick={() => setStatus(1)}
        >
          Stopped
        </Button>
        <SelectFromCenter
          value={filter}
          setValue={setFilter}
          collection="roles"
          label="filterByRole"
          size="small"
          style={{ width: 200 }}
          none
        />
        <AddButton
          action={() => setNewStaff(true)}
          tooltip={<Lang w="addANewStaffMember" />}
        />
        <NewStaffDialog
          open={newStaff}
          setOpen={setNewStaff}
          data={data}
        />
      </div>
      <br></br>
      {data.loading ? (
        <Loading />
      ) : (
        <table>
          {data
            .filter((s) => (filter === "" ? true : s.roles.includes(filter)))
            .map((s) => (
              <tr>
                <td> {s.name}</td>
                <td> {format(new Date(s.createdAt), "dd/MM/yyyy")}</td>
                <td>{s.roles ? s.roles.toString() : ""}</td>
                <td>
                  {status === 1 ? (
                    "Stopped"
                  ) : (
                    <Button
                      onClick={async () => {
                        await mut("post", `/user/stop/${s._id}`);
                        replaceById(data, s.id, { ...s, status: 1 });
                      }}
                    >
                      Stop
                    </Button>
                  )}
                </td>
                <td>
                  <VND
                    value={s.salary ? s.salary : ""}
                    setValue={(value) => data.update({ ...s, salary: value })}
                    url={`/users-hr/${s._id}`}
                    field="salary"
                    size="small"
                  />
                </td>
              </tr>
            ))}
        </table>
      )}
    </div>
  );
}
