export const weekdays = [
  ["Sunday", "chủ nhật"],
  ["Monday", "Thứ hai"],
  ["Tuesday", "Thứ ba"],
  ["Wednesday", "Thứ Tư"],
  ["Thursday", "Thứ năm"],
  ["Friday", "Thứ sáu"],
  ["Saturday", "Thứ bảy"],
];
export const wkdays = [
  { name: ["Monday", "Thứ hai"], day: 1 },
  { name: ["Tuesday", "Thứ ba"], day: 2 },
  { name: ["Wednesday", "Thứ Tư"], day: 3 },
  { name: ["Thursday", "Thứ năm"], day: 4 },
  { name: ["Friday", "Thứ sáu"], day: 5 },
  { name: ["Saturday", "Thứ bảy"], day: 6 },
  { name: ["Sunday", "chủ nhật"], day: 0 },

];

export const monthnames = [
  ["", ""],
  ["January", "Tháng Giêng"],
  ["February", "Tháng hai"],
  ["March", "Tháng Ba"],
  ["April", "Tháng tư"],
  ["May", "Tháng năm"],
  ["June", "Tháng sáu"],
  ["July", "Tháng bảy"],
  ["August", "Tháng tám"],
  ["September", "Tháng Chín"],
  ["October", "Tháng Mười"],
  ["November", "Tháng mười một"],
  ["December", "Tháng mười hai"],
];

export const scoreColours = [
  "#FFFFFF",
  "#ffb8b8",
  "#ffdfb8",
  "#fdffb8",
  "#d3ffb8",
  "#8cf59b",
];

export const scoreColoursBold = [
  "#FFFFFF",
  "#f77ea9",
  "#ffaa00",
  "#d6d606",
  "#9dfa07",
  "#24fa07",
];

export const classColors = [
  { name: "Red", color: "#ff4d4d" },
  { name: "Green", color: "#4dff4f" },
  { name: "Blue", color: "#4dd2ff" },
  { name: "Purple", color: "#be4dff" },
  { name: "Yellow", color: "#ffed4d" },
  { name: "Orange", color: "#ff9d4d" },
  { name: "White", color: "#FFFFFF" },
];

export const classLevel = ["Beginner", "Intermediate", "Advanced"];

export const status = ["New", "Active", "Pending", "Stopped", "Lead"];

export const statusColors = {
  Active: "#0094ab",
  New: "#00ab41",
  Pending: "#a8ab00",
  Stopped: "#ab0033",
  Lead: "#bf4a02",
  HotLead: "#b902bf",
};

export const shortWeekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]

export const gradients = {
    darkBlue: "linear-gradient(to right, #0f2027, #203a43, #2c5364)"
}