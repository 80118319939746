import React, { useState } from "react";
import Lang from "../../components/Lang";
import useDBO from "../../hooks/useDBO";
import Loading from "../../Loading";
import AddButton from "../../components/AddButton";
import DialogHT from "../../components/DialogHT";
import VND from "../../components/VND";
import DeleteButton from "../../components/DeleteButton";
import Text from "../../components/Text";
import { Link } from "react-router-dom";
  
  
  export default function Books(props) {
  const [newBook, openNewBook] = useState(false);
  const books = useDBO(`/books`);
  
  if (books.loading) return <Loading />;
  
    return (
      <div>
        <div style={{display:"flex", justifyContent: "space-between"}}>
          <div>
            <Lang w="bookList" />
          </div>
          <AddButton action={() => openNewBook(true)} tooltip={<Lang w="addANewBook" />} />
          <DialogHT
            open={newBook}
            setOpen={openNewBook}
            url="/books"
            title="addANewBook"
            content={[{ name: "name", label: "name" }, { name: "link", label: "link" }, { name: "sellPrice", label: "price", type: "vnd" }]}
            http="put"
            success={(res)=>books.add(res)}
          />
        </div>
        {books.get.map((book) => (
          <div style={{ display: "flex", gap: 10, alignItems:"center", borderBottom:"1px solid #ccc" }}>
            <div style={{ width: 200, minWidth: 200 }}>{book.name}</div>
            <div><a href={book.link} target="blank">{book.link} </a></div>
            <div style={{ width: 100, minWidth: 100, textAlign: "right" }}><VND value={book.sellPrice} /></div>
            <div style={{flexGrow:2}}></div>
            <div><DeleteButton route="/books" id={book._id} success={(res)=>books.remove(res)} /></div>
          </div>
        ))}
      </div>
    );
  };