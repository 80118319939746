import {Button,Dialog,DialogActions,DialogContent,DialogTitle} from "@mui/material";
import React, { useContext, useState } from "react";
import SelectFromList from "../../../components/SelectFromList";
import { StaffContext } from "../../../../../Context";
import mut from "../../../hooks/mut";
import replaceById from "../../../hooks/replaceById";
import DayOfWeek from "../../../components/DayOfWeek";
import TimeClock from "../../../components/TimeClock";


export default function NewForeignTeacherDialog(props) {
  const { open, setOpen,htClass,classDay,setClasses,week } = props;
  const { getStaffRole } = useContext(StaffContext)
  const [teacher, setTeacher] = useState("")
  const [startTime, setStartTime] = useState("")
  const [endTime, setEndTime] = useState("")
  
  

    
  return (
    <Dialog open={open}>
      <DialogTitle>
        Update the foreign teacher for {htClass.name} on{" "}
        <DayOfWeek day={classDay.day} />
      </DialogTitle>
      <DialogContent>
        <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
          <SelectFromList
            choices={getStaffRole("Foreign Teacher")}
            value={teacher}
            setValue={setTeacher}
            fullWidth
          />
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <TimeClock value={startTime} setValue={setStartTime} label="startTime" />
            <TimeClock value={endTime} setValue={setEndTime} label="finishingTime" />
          </div>
         {/*  <TimeChooserList
            label="Start time"
            value={startTime}
            setValue={setStartTime}
            times={[
              classDay.startTime,
              timeAdd(classDay.startTime, 30),
              timeAdd(classDay.startTime, 60),
            ]}
            otherStart={classDay.startTime}
            otherEnd={classDay.endTime}
          />
          <TimeChooserList
            label="Finishing time"
            value={endTime}
            setValue={setEndTime}
            times={[
              timeAdd(classDay.startTime, 30),
              timeAdd(classDay.startTime, 60),
              timeAdd(classDay.startTime, 90),
            ]}
          /> */}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="error"
          onClick={() => setOpen(false)}
        >
          Close
        </Button>
        <Button
          color="success"
          onClick={async () => {
            let result = await mut(
              "post",
              `/classes/${htClass._id}/classDay/${classDay._id}/${week.tDate()}/add-foreign-teacher`,
              { teacher, startTime, endTime }
            );
           
            replaceById(setClasses, result);
            setOpen(false)
          }}
          disabled={teacher && startTime && endTime ? false : true}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}