function makeTDate(dt = new Date()) { 
    const year = dt.getFullYear();
    const month = String(dt.getMonth() + 1).padStart(2, "0");
    const day = String(dt.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`
}

function addDateFunctions() {
  Date.prototype.ymd = function () {
    return {
      year: this.getFullYear(),
      month: this.getMonth() + 1,
      day: this.getDate(),
    };
  };

  Date.prototype.ymdd = function () {
    return {
      year: this.getFullYear(),
      month: this.getMonth() + 1,
      day: this.getDate(),
      dayOfWeek: this.getDay(),
    };
  };

  Date.prototype.ymddt = function () {
    return {
      year: this.getFullYear(),
      month: this.getMonth() + 1,
      day: this.getDate(),
      dayOfWeek: this.getDay(),
      tDate: makeTDate(this),
    };
  };

  Date.prototype.ymdhms = function () {
    return {
      year: this.getFullYear(),
      month: this.getMonth() + 1,
      day: this.getDate(),
      hour: this.getHours(),
      minute: this.getMinutes(),
      second: this.getSeconds(),
    };
  };

  Date.prototype.tDate = function () {
    return makeTDate(this);
  };

  Date.prototype.isToday = function () {
    const today = new Date();
    return (
      this.getFullYear() === today.getFullYear() &&
      this.getMonth() === today.getMonth() &&
      this.getDate() === today.getDate()
    );
  };

  Date.prototype.startOfWeek = function () {
    const startOfWeekDate = new Date(this)
    if (startOfWeekDate.getDay() === 0) {
      startOfWeekDate.setDate(startOfWeekDate.getDate() - 6)
    } else {
      startOfWeekDate.setDate(startOfWeekDate.getDate() - (startOfWeekDate.getDay() - 1))
    }
    return makeTDate(startOfWeekDate);
  };

  Date.prototype.startOfWeekAsDate = function () {
     const startOfWeekDate = new Date(this);
     if (startOfWeekDate.getDay() === 0) {
       startOfWeekDate.setDate(startOfWeekDate.getDate() - 6);
     } else {
       startOfWeekDate.setDate(
         startOfWeekDate.getDate() - (startOfWeekDate.getDay() - 1)
       );
     }
     return startOfWeekDate;
  };

  Date.prototype.equals = function (dateIn) {
    if (this.getFullYear() === dateIn.getFullYear()
    && this.getMonth()=== dateIn.getMonth() &&
    this.getDate() === dateIn.getDate()) {
      return true;
    } else {
      return false;
    }
  }
  Date.prototype.fDate = function () {
   return `${this.getDate()}/${this.getMonth() + 1}/${this.getFullYear()}`
  }
  Date.prototype.addDays = function (days) {
    const date = new Date(this);
    date.setDate(date.getDate() + days);
    return date;
  }
  Date.prototype.addMonths = function (months) {
    const date = new Date(this);
    date.setMonth(date.getMonth() + months);
    return date;
  }
}

module.exports = addDateFunctions;
