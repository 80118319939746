import "./Home.css";
import React, { useContext} from "react";
import Loading from "../../Loading";
import Messages from "./components/Messages";

import Days from "./components/Days";
import { UserContext } from "../../../../Context";



export default function Home(props) {
  const { user } = useContext(UserContext);
  return (
    <div>
      <h1>Hello {user.name}</h1>
      <Days />
      <Messages/>
    </div>
  );
}
