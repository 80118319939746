import { addDays, format, startOfWeek } from "date-fns";
import { endOfWeek } from "date-fns";
import React from "react";
import IconButtonHT from "./IconButtonHT";
import Lang from "./Lang";


export default function WeekChooser({value,setValue,label,style={}}) {

const start = startOfWeek(value,{weekStartsOn:1})
const end = endOfWeek(value, { weekStartsOn: 1 });
    
  return (
    <div style={{height:72,...style }}>
      <div style={{color:"#888", fontSize:12, position:"relative", top:9, left:60, backgroundColor:"white", width:35, paddingLeft:3, paddingRight:3}}>{label ? <Lang w={label} /> : <Lang w="week" />}</div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 5,
          marginRight: 10,
        }}
      >
        <IconButtonHT
          icon="leftArrow"
          action={() => setValue(addDays(value, -7))}
        />
        <div style={{ border: "1px solid #ccc", borderRadius: 4, padding: 7, paddingLeft: 15, paddingRight: 15 }}>
          {format(start, "dd/MM")} - {format(end, "dd/MM/yy")}
        </div>
        <IconButtonHT
          icon="rightArrow"
          action={() => setValue(addDays(value, 7))}
        />
      </div>
    </div>
  );
}