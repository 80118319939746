import React from "react";
import HomeIcon from "@mui/icons-material/Home";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import SchoolIcon from "@mui/icons-material/School";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import CommentIcon from "@mui/icons-material/Comment";
import FeedbackIcon from "@mui/icons-material/Feedback";
import CallIcon from "@mui/icons-material/Call";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CloseIcon from "@mui/icons-material/Close";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";

/**
 * Returns a Material UI icon based on the name given
 * 
 * choices: 
 * 
 * home, timetable, attendance, money, staff, settings, feedback, comments, bike, call, student, 
 * add, edit, delete,
 * menu
 * leftArrow, rightArrow
 * close
 * book
 * 
 *  - [Search more icons](https://mui.com/material-ui/material-icons/)
 * 
 * @param {{name:string}} props.name
 * @returns {React.ReactElement}
 */

export default function Icon({name}) {

    if(name == "home") return(<HomeIcon/>)
    if(name == "timetable") return(<AccessTimeIcon/>)
    if(name == "attendance") return(<AssignmentIcon/>)
    if(name == "money") return(<AttachMoneyIcon/>)
    if(name == "staff") return(<PersonIcon/>)
    if(name == "settings") return(<SettingsIcon/>)
    if(name == "feedback") return(<FeedbackIcon/>)
    if(name == "comments") return(<CommentIcon/>)
    if(name == "bike") return(<TwoWheelerIcon/>)
    if(name == "call") return (<CallIcon />) 
    if(name == "student") return (<SchoolIcon />)
    if(name == "delete") return <DeleteIcon />;
    if(name == "add") return <AddIcon />;
    if(name == "edit") return <EditIcon />;
    if(name == "menu") return <MenuIcon />;
    if(name == "leftArrow") return <ArrowBackIcon />;
    if(name == "rightArrow") return <ArrowForwardIcon />;
    if (name == "close") return <CloseIcon />;
    if(name == "book") return <AutoStoriesIcon />

return(<div>Icon</div>)
}