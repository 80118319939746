import React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { net } from "../../../../database";
import Message from "../home/components/Message";
import Loading from "../../Loading";
import { VariableSizeList as List } from "react-window";
import Search from "../../components/Search";
import { useWindowSize } from "@react-hook/window-size/throttled";
import useDBO from "../../hooks/useDBO";
import Lang from "../../components/Lang";
import { Button } from "@mui/material";

export default function ManageMessages(props) {

  const [showClosed, setShowClosed] = React.useState("open");
  const messages = useDBO(`/manage/messages/${showClosed}/allstaff`);
  const [search, setSearch] = React.useState("")
  const [unanswered, setUnanswered] = React.useState(false)
  const [width, height] = useWindowSize({ fps: 60 });

  if (messages.loading) return <Loading />;

  let msgs = messages.get
  if(unanswered) msgs = msgs.filter(m => !m.chats || m.chats.length === 0)
  if (search !== "") {
    msgs = messages.filter((m) => {
      if (m.studentsTagged.map((student) => student.name).toString().toLocaleUpperCase().includes(search.toLocaleUpperCase())) return true
      if (
        m.description
          .toLocaleUpperCase()
          .includes(search.toLocaleUpperCase())
      )
        return true;
      return false
    })
}


  function DrawMessages(props) {
    if (messages.get.length === 0) return <div><Lang w="noMessagesToShow" /></div>;
 
    for (const m of msgs) {
      if (!m.chats || m.chats.length < 1) {
        m.lastUpdate =  m.createdAt 
      } else {
        const chat = m.chats[m.chats.length-1]
        m.lastUpdate = chat.createdAt 
      }
    }

     msgs.sort((a, b) => new Date(b.lastUpdate) - new Date(a.lastUpdate));
    /* msgs.sort((a, b) => {
      
    }) */

  

    return (
      <List
        itemCount={msgs.length}
        height={height-250}
        itemSize={(index) => {
          if (!msgs[index].chats || msgs[index].chats.length === 0)
            return 150;
          return 350;
        }}
        /*     style={{ padding: 0, margin: 0 }} */
      >
        {Row}
      </List>
    );
  }

  const Row = ({ index, style }) => (
    <Message
      message={msgs[index]}
      setMessages={messages.set}
      key={msgs[index]._id}
      {...props}
      messages={messages}
      style={style}
    />
  );

  return (
    <div>
      <h1><Lang w="messages" /> ({msgs.length})</h1>
      <div
        style={{
          display: "flex",
          marginBottom: 5,
          height: "100%",
        }}
      >
        <ShowToggleButtons
          value={showClosed}
          setValue={setShowClosed}
          buttons={[
            { label: <Lang w="open"/>, value: "open" },
            { label: <Lang w="closed"/>, value: "closed" },
          ]}
        />
        <Button onClick={() => setUnanswered(!unanswered)}>{unanswered ? <Lang w="showAll" /> : <Lang w="unanswered" />}</Button>
        <Search search={search} setSearch={setSearch} size="small"/>
      </div>

      <DrawMessages
        messages={messages}
        removeMessage={messages.remove}
        {...props}
      />
    </div>
  );
}

function ShowToggleButtons(props) {
  const { value, setValue, buttons } = props;
  return (
    <div style={{ marginRight: 10 }}>
      <ToggleButtonGroup
        value={value}
        onChange={(e) => setValue(e.target.value)}
        size="small"
      >
        {buttons.map((button) => (
          <ToggleButton key={button.label} value={button.value}>
            {button.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  );
}
