import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { debounce } from "../hooks/debounce";
import VND from "./VND";

/**
 * @description Debounced text field
 * @param {string} url optional - post updates to this url
 * @param {string} field optional - update this field
 * @param {number} delay optional - delay in ms before sending, default 1000
 * @param {number} fontSize optional - font size in px, default 16
 * @param {boolean} vnd optional - format as VND
 * @param {string} align optional - left, center, right 
 * @returns 
 */

export default function Text(props) {
const { value, setValue, url, field, delay=1000, fontSize=16, vnd=false, align="left" } = props
const [first, setFirst] = useState(false);  // don't fire debounce on first render
      useEffect(() => {
        let db = debounce(url, field, value, first, delay);
        setFirst(true);
        return db;
      }, [value]);

  if(vnd) return (<VND {...props} value={value} setValue={setValue} />)
  
  return (<TextField value={value} onChange={(e) => setValue(e.target.value)} inputProps={{style:{fontSize, textAlign:align}}}{...props} />)
}