import React, { useContext } from "react";
import Message from "./Message";
import Loading from "../../../Loading";
import { StaffContext } from "../../../../../Context";
import useDBO from "../../../hooks/useDBO";
import Lang from "../../../components/Lang";

export default function Messages() {
  const { staff } = useContext(StaffContext)
  const messages = useDBO("/home-messages");
  if (!staff || messages.loading) return <Loading />;
  return (
    <div>
      {messages.get.length > 0 ? (
        <div>
          <h3><Lang w="messages"/></h3>
          {messages.get.map((message) => (
            <Message
              key={message._id}
              message={message}
              removeMessage={messages.remove}
              staff={staff}
              messages={messages}
            />
          ))}
        </div>
      ) : (
        "No messages"
      )}
    </div>
  );
}
