import React from "react";
import Text from "./Text";


export default function UpdateUnitAndLesson({htClass, setHtClass}) {
return (
  <div
    style={{
      display: "flex",
      justifyContent: "space-between",
    marginTop: 10,
    }}
  >
    <Text
      label="Unit"
      value={htClass.currentUnit || ""}
      type="number"
      setValue={(currentUnit) =>
      setHtClass({ ...htClass, currentUnit })}
      size="small"
      style={{ width: 90 }}
      url={`/classes/${htClass._id}/${new Date().startOfWeek()}/thisClassDB`}
            field="currentUnit"
          
        />
    
    <Text
      label="Lesson"
      value={htClass.currentLesson || ""}
      type="number"
      setValue={(currentLesson) =>
        setHtClass({ ...htClass, currentLesson })}
      size="small"
      style={{ width: 90 }}
      url={`/classes/${htClass._id}/${new Date().startOfWeek()}/thisClassDB`}
      field="currentLesson"
    />
    {/* <TextField
      label="Unit"
      value={htClass.currentUnit || ""}
      type="number"
      onChange={(e) => {
        setHtClass((prev) => ({
          ...prev,
          currentUnit: e.target.value,
        }));
      }}
      onBlur={() =>
        mut(
          "post",
          `/classes/${htClass._id}/${new Date().startOfWeek()}/thisClassDB`,
          {
            currentUnit: htClass.currentUnit,
          }
        )
      }
      size="small"
      style={{ width: 80, marginTop: 5 }}
    />
    <TextField
      label="Lesson"
      value={htClass.currentLesson || ""}
      type="number"
      onChange={(e) => {
        let currentLesson = e.target.value;

        setHtClass((prev) => ({ ...prev, currentLesson }));
      }}
      onBlur={() =>
        mut(
          "post",
          `/classes/${htClass._id}/${new Date().startOfWeek()}/thisClassDB`,
          {
            currentLesson: htClass.currentLesson,
          }
        )
      }
      size="small"
      style={{ width: 80, marginTop: 5 }}
    /> */}
  </div>
);
}