import {Button,Dialog,DialogActions,DialogContent,DialogTitle} from "@mui/material";
import React, { useContext, useState } from "react";
import SelectFromList from "../../../components/SelectFromList";
import { StaffContext } from "../../../../../Context";
import mut from "../../../hooks/mut";
import { set } from "lodash";
import replaceById from "../../../hooks/replaceById";
import DayOfWeek from "../../../components/DayOfWeek";

import timeAdd from "../../../hooks/timeAdd";
import TimeChooserList from "../../../components/TimeChooserList";
import TimeClock from "../../../components/TimeClock";


export default function ChangeForeignTeacherDialog(props) {
  const { open, setOpen,htClass,classDay,setClasses,week } = props;
  const { getStaffRole } = useContext(StaffContext)
 /*  const [otherTeacher, setTeacher] = useState(classDay.otherTeachers[0] || undefined) */
  
    const updateTeacher = async (otherTeachers) => {
      
       
   
      let result = await mut("post", `/classes/${htClass._id}/update-classday/${classDay._id}/${week.tDate()}`, { ...classDay, otherTeachers })

        replaceById(setClasses,result)
    }
    

    
  return (
    <Dialog open={open}>
      <DialogTitle>
        Update the foreign teacher for {htClass.name} on{" "}
        <DayOfWeek day={classDay.day} />
      </DialogTitle>
      <DialogContent>
        {classDay.otherTeachers.map((otherTeacher) => (
          <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
            <SelectFromList
              choices={getStaffRole("Foreign Teacher")}
              value={otherTeacher.teacher}
              setValue={(newTeacher) =>
                updateTeacher({ ...otherTeacher, teacher: newTeacher })
              }
              fullWidth
            />
            <div style={{display:"flex", justifyContent: "space-around"}}>
              <TimeClock
                label="startTime"
                value={otherTeacher.startTime}
                setValue={(startTime) =>
                  updateTeacher({ ...otherTeacher, startTime })
                }
              />
              <TimeClock
                label="finishingTime"
                value={otherTeacher.endTime}
                setValue={(endTime) =>
                  updateTeacher({ ...otherTeacher, endTime })
                }
              />
            </div>
          </div>
        ))}
      </DialogContent>
      <DialogActions>
        <Button
          color="success"
          onClick={() => setOpen(false)}
        >
          OK
        </Button>
        {/*    <Button color="success">Go</Button> */}
      </DialogActions>
    </Dialog>
  );
}