import React, { useContext, useState } from "react";
import "./money.css";
import useDBO from "../../../hooks/useDBO";
import Loading from "../../../components/Loading";
import Error from "../../../components/Error";
import VND from "../../../components/VND";
import Lang from "../../../components/Lang";
import { LanguageContext } from "../../../../../Context";
import AddButton from "../../../components/AddButton";
import NewCostDialog from "./NewCostDialog";
import DialogHT from "../../../components/DialogHT";


export default function Costs({ costType, date, setLoading }) {
  
    const dt = new Date(date)

    const costs = useDBO(`/costs?paymentMethod=${costType}&year=${dt.getFullYear()}&month=${dt.getMonth() + 1}&day=${dt.getDate()}`)
    const [open,setOpen]=useState(false)
   
setLoading(costs.loading)
if (costs.loading) return <Loading />;
if (costs.error) return <Error />;



    return (
      <div className="money-outline">
        <div
          className="money-subtitle"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div>
            <Lang w="costs" />
          </div>
          <AddButton
            action={() => setOpen(true)}
            tooltip={<Lang w="addANewCost" />}
          />
        </div>
        {costs.map((c) => (
          <DrawCost
            cost={c}
            key={c._id}
          />
        ))}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontWeight: "bold",
            marginTop: 5,
          }}
        >
          <div>
            <Lang w="total" />:
          </div>
          <div>
            <VND value={costs.reduce("amount")} />
          </div>
        </div>
        {/* <NewCostDialog
          open={open}
          setOpen={setOpen}
          costs={costs}
          paymentMethod={costType}
          date={date}
        /> */}
        <DialogHT
          open={open}
          setOpen={setOpen}
          title={"addANewCost"}
          content={content}
          success={(data) => costs.add(data)}
          url="/costs"
          extraData={{ paymentMethod: costType, tDate: date }}
          http="put"
        />
      </div>
    );
}

function DrawCost({ cost }) {
     const { language } = useContext(LanguageContext);
    return (<div className="money-cost">
        <div>{language===0 && cost.description_e ? cost.description_e : cost.description}</div>
        <div><VND value={cost.amount} /></div>
    </div>)
}   

const content = [
  { label: "location", name: "location", type: "location", all: true },
  { label: "typeOfCost", name: "category", type: "centerChoices", field: "costType" },
  { label: "whoOrderedThisCost?", name: "orderedBy", type: "staff" },
  { label: "description", name: "description", type: "text", multiline: true, rows: 3 },
  { label: "amount",name:"amount",type:"vnd"},
]