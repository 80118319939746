import React from "react";
import Icon from "../Icon";
import { IconButton } from "@mui/material";


/**
 * A simple button that shows an icon. The only prop it needs is 'icon', which should be the name of the icon to show.
 * It also takes an 'action' prop, which is the function to call when the button is clicked.
 *
 * @param {string} icon The name of the icon to show
 * @param {function} action The function to call when the button is clicked
 * @returns {React.Component} A button with the specified icon that will call the specified action when clicked.
 */
export default function IconButtonHT({icon, action, disabled=false}) {


    return (<IconButton onClick={action} disabled={disabled}><Icon name={icon}/></IconButton>)
}