import React, { useContext } from "react";
import { CenterContext } from "../../../Context";
import SelectFromList from "./SelectFromList";
import Lang from "./Lang";


/**
 * SelectFromCenter
 * 
 * A component that renders a selection list from a specified collection in the center context.
 * 
 * @param {string} value The current value of the selection.
 * @param {function} setValue Callback function to update the value.
 * @param {string} collection The name of the collection in the center context to display choices from.
 * @param {string} label The label to display above the selection list.
 * @param {boolean} none If true, include a "None" option in the list.
 * @param {boolean} fullWidth If true, the selection list will occupy the full width available.
 * @param {string} size The size of the selection component.
 * @param {object} style Custom styles to apply to the component.
 * @param {string} url Optional URL to post to when the value changes.
 * @param {string} field The field in the center context to validate and update.
 * 
 * @returns A JSX element rendering the selection list or a message if the field is not found.
 */
export default function SelectFromCenter({value, setValue, collection, label, none, fullWidth, size, style, url, field }) {
    const { center } = useContext(CenterContext)
    
if(!center[collection]) return <>No collection called {collection} in the center</>
    return (<div><SelectFromList
        value={value}
        setValue={setValue}
        choices={center[collection]}
        label={<Lang w={label} />}
        fullWidth={fullWidth}
        none={none}
        size={size}
        style={style}
        url={url}
        field={field}
    /></div>)
}