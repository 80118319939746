import React from "react";
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import { TextField } from "@mui/material";
import { addDays, format } from "date-fns";
import { net } from "../../../database";
import IconButtonHT from "./IconButtonHT";

/**
 * A date picker component that allows users to select a date.
 * 
 * @param {object} props The properties passed to the component.
 * @param {string|Date} props.value The current value of the date picker.
 * @param {function} props.setValue Callback function to update the selected date.
 * @param {array|function} [props.enable] Dates to enable in the date picker.
 * @param {object} [props.style={}] Custom styles for the date picker.
 * @param {string} [props.label=""] Label for the date picker input.
 * @param {boolean} [props.noPast] If true, only allow future dates.
 * @param {string} [props.align] Alignment of the date input ('left', 'center', 'right').
 * @param {string} [props.size="small"] Size of the input field ('small', 'normal').
 * @param {string} [props.url=""] Optional URL to post the selected date.
 * @param {string} [props.field=""] The field name to update when posting to the URL.
 * @returns A Flatpickr component wrapped in a TextField.
 */
export default function DatePicker(props) {
  const { value, setValue, enable, style = {}, label = "", noPast,align,size="small", url="", field="",buttons=false,disabled=false } = props;

  let options = { dateFormat: "d/m/Y" };
  if (enable) options.enable = enable;
  if (noPast) options.enable = [(date) => date >= Date.now()];

  let sd = ""
  try {
    sd = format(value, "dd/MM/yyyy")
  } catch (err) {
    console.log("invalid date")
  }

  let inputProps = {}
  if (align) {
    inputProps.style = {textAlign: align}
  }

  const update = (date) => {

     if (url && field) {
       net.post(url, { [field]: formatISO(date) });
     }
    setValue(date)
  }

  if(buttons) return (
    <div>
      <IconButtonHT
        icon="leftArrow"
        action={() => setValue(addDays(value, -1))}
        disabled={disabled}
      />
      <Flatpickr
        value={value}
        onChange={([date]) => update(date)}
        options={options}
        disabled={disabled}
        render={({ defaultValue, value, ...props }, ref) => {
          return (
            <TextField
              value={value === "" || sd}
              inputRef={ref}
              size={size}
              style={style}
              label={label}
              inputProps={inputProps}
            />
          );
        }}
      />
      <IconButtonHT
        icon="rightArrow"
        action={() => setValue(addDays(value, 1))}
        disabled={disabled}
      />
    </div>
  );
  return (

    <Flatpickr
      value={value}
      onChange={([date]) => update(date)}
      options={options}
      render={({ defaultValue, value, ...props }, ref) => {
        return (
          <TextField
            value={value==="" || sd}
            inputRef={ref}
            size={size}
            style={style}
            label={label}
            inputProps={inputProps}
          />
        );
      }}
      />

  );
}


function formatISO(date) {
  let dt = ""
  dt += date.getFullYear() + "-"
  dt += make2Digits(date.getMonth() + 1) + "-"
  dt += make2Digits(date.getDate())
  return dt
}

function make2Digits(number) {
  const numberIn = `${number}`
  return numberIn.length>1 ? `${numberIn}` : `0${numberIn}`
}