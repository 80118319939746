import React, { useContext } from "react";
import { CenterContext } from "../../../Context";
import SelectFromList from "./SelectFromList";


export default function CenterChoices({value,setValue,field, label}) {
    const { center } = useContext(CenterContext)
    if (!center[field]) return <div>No field called {field}</div>

return(<SelectFromList choices={center[field]} value={value} setValue={setValue} label={label} fullWidth/>)
}