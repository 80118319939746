import React from "react";
import useDB from "../../hooks/useDB";
import Loading from "../../components/Loading";
import Error from "../../components/Error";
import SelectFromList from "../../components/SelectFromList";
import { addDays, format } from "date-fns";



export default function History({value,setValue}) {


const [history, setHistory, error, loading] = useDB(`/ft-timetable-history`);


if (loading) return <></>;
if (error) return <Error />;


const choices = history.map((h) => ({
  _id: h,
  name: `${format(new Date(h), "dd/MM/yy")} - ${format(addDays(new Date(h), 6), "dd/MM/yy")}`,
}));


    return (<div style={{ margin: 10, display: "flex" }}><div style={{flexGrow:1}}>{" "}</div><SelectFromList choices={choices} value={value} setValue={setValue} style={{width:200}} label="History" size="small" none/></div>)
}