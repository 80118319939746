import {Button,Dialog,DialogActions,DialogContent,DialogTitle} from "@mui/material";

import React, { useContext, useState } from "react";
import Lang from "../../../components/Lang";
import VND from "../../../components/VND";
import { UserContext } from "../../../../../Context";
import LocationChoice from "../../../components/LocationChoice";
import mut from "../../../hooks/mut";

export default function WithdrawDialog(props) {
    const { open, setOpen, withdrawals } = props;
    const [amount, setAmount] = useState("");
    const [float, setFloat] = useState("");
    const [location, setLocation] = useState(
      localStorage.getItem("systemLocation") || ""
    );
    const { user } = useContext(UserContext)

    const withdraw = async () => {
        let result = await mut("post", "/cashwithdrawals", {
          amount, float, location
        })
        withdrawals.set(result)
        setAmount("")
        setFloat("")
        setOpen(false)
    }
    

  return (
    <Dialog
      open={open}
      fullWidth
    >
      <DialogTitle style={{display:"flex", justifyContent: "space-between"}}><div><Lang w="cashWithdrawalMadeBy:" /></div><div>{user.name}</div></DialogTitle>
      <DialogContent style={{ padding: 10, display: "flex", gap: 20, flexDirection: "column" }}>
        <VND
          value={amount}
          setValue={setAmount}
          label={<Lang w="amount" />}
          fullWidth
        />
        <VND
          value={float}
          setValue={setFloat}
          label={<Lang w="float" />}
          fullWidth
              />
              <LocationChoice value={location} setValue={setLocation} fullWidth size="normal" />
      </DialogContent>
      <DialogActions>
        <Button
          color="error"
          onClick={() => setOpen(false)}
        >
          Close
        </Button>
        <Button
            color="success"
                  disabled={amount && float ? false : true}
                  onClick={withdraw}
            >
            Go
        </Button>        
      </DialogActions>
    </Dialog>
  );
}