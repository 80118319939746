import addDateFunctions from "dateoverrides";

/**
 * Updates the prototypes of built-in types with additional methods.
 *
 * This adds methods to Array, including:
 *
 * - `getLast()`: Returns the last element in the array, or undefined if the array is empty.
 * - `removeById(id)`: Removes the first element in the array that matches the given id.
 */
export default function updatePrototypes() {


    /**
     * Get the last element in the array. If the array is empty, return undefined.
     * @returns {any}
     */
    Array.prototype.getLast = function () {
        if (this.length > 0) return this[this.length - 1];
        return undefined;
    };

    /**
     * Removes the first element in the array that matches the given id.
     * @param {string} id - the id of the element to remove
     */
    Array.prototype.removeById = function (id) {
        const index = this.findIndex(item => item.id === id);
        if (index !== -1) {
            this.splice(index, 1);
        }
    };

    /**
     * Check if at least one element in the given array is included in this array.
     * @param {Array} ArrayIn - The array to check
     * @returns {boolean} true if at least one element is included, false otherwise
     */
    Array.prototype.includesOne = function (ArrayIn) {
        for (const item of ArrayIn) {
        if(this.includes(item)) return true
        }
        return false
    }
    addDateFunctions();
}