
import React, { useContext, useState } from "react";
import Loading from "../../Loading";
import Staff from "../../components/Staff";
import DeleteButton from "../../components/DeleteButton";
import HtDate from "../../components/HtDate";
import Time from "../../components/Time";
import { CenterContext } from "../../../../Context";
import HtTabs from "../../components/HtTabs";
import FormatDateTime from "../../components/FormatDateTime";
import useDBO from "../../hooks/useDBO";
import AddButton from "../../components/AddButton";
import NewShiftDialog from "./NewShiftDialog";
/* import ForeignTeacher from "./ForeignTeacher"; */

export default function StaffShifts(props) {
  const [newShiftDialog, setNewShiftDialog] = useState(false);
 
  let d = new Date();
  let [showDate, setShowDate] = useState(
    new Date(d.getFullYear(), d.getMonth(), d.getDate(), 7)
  );

  const shifts = useDBO(
    `/staff/shifts2/${showDate.getFullYear()}/${showDate.getMonth()}/${showDate.getDate()}`
  );





  const updateDate = (newDate) => {
    let d = new Date(newDate);
    d.setHours(d.getHours() + 7);

    setShowDate(d);
  };

  

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h1>Shifts for staff today</h1>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <HtDate
            value={showDate}
            setValue={updateDate}
            buttons
            style={{ width: 120 }}
            disabled={shifts.loading}
            future={false}
          />

          <AddButton action={() => setNewShiftDialog(true)} />
        </div>
      </div>
         <DrawTabs shifts={shifts} newShiftDialog={newShiftDialog} setNewShiftDialog={setNewShiftDialog} showDate={showDate}/>
     
    </div>
  );
}

function DrawTabs({shifts, newShiftDialog,setNewShiftDialog,showDate}){ 
  const { center } = useContext(CenterContext);
  if(shifts.loading) return <Loading />;
  const labels = center.locations
    .filter((l) => l.name !== "All locations")
    .map(
      (l) =>
        `${l.name} (${shifts.get.filter((s) => s.location === l._id).length})`
    );
  const components = center.locations
    .filter((l) => l.name !== "All locations")
    .map((l) => (
      <DrawLocation
        shifts={shifts}
        location={l._id}
        key={l._id}
      />
    ));
  return (
    <>
      <HtTabs
        labels={labels}
        components={components}
        localStorage="location"
      />
      <NewShiftDialog open={newShiftDialog} setOpen={setNewShiftDialog} showDate={showDate} shifts={shifts}/>
    </>
  );
}


function DrawLocation({ shifts, location, ft }) {
  
  const staff = [...new Set(shifts.get.filter(s=>s.location===location).map((s) => s.user))]
 
  return (
    <div>
      <div style={{ display: "flex" }}>
        <div style={{ width: 200, fontSize: 11 }}>Name</div>

        <div style={{ width: 100, fontSize: 11, textAlign: "center" }}>
          Start Time
        </div>
        <div style={{ width: 100, fontSize: 11, textAlign: "center" }}>
          Finish Time
        </div>
        <div style={{ width: 100, fontSize: 11, textAlign: "center" }}>
          Hours
        </div>
        <div style={{ width: 150, fontSize: 11, textAlign: "center" }}>
          Recorded by
        </div>
        <div style={{ width: 100, fontSize: 11, textAlign: "center" }}>
          Date and time
        </div>
      </div>

      <hr />
      <div style={{display:"flex", flexDirection:"column", gap:5}}>
        {staff.map((s) => (
          <DrawShift
            staff={s}
            shifts={shifts}
            location={location}       
          />
        ))}
      
      </div>
    </div>
  );
}

function DrawShift(props) {
  let { staff, shifts,location} = props;
  return (
    <div
      style={{
        display: "flex",
        borderBottom: "1px solid black",
        paddingTop: 5,
        paddingBottom: 5,
      }}
      key={staff._id}
    >
      <div style={{ width: 200 }}>
        <Staff id={staff} />
      </div>
      <div>
        {shifts.get
          .filter((s) => s.user === staff && s.location === location)
          .map((shift) => (
            <div
              key={shift._id}
              style={{ display: "flex", alignItems: "center" }}
            >
              <div style={{ width: 100, textAlign: "center" }}>
                <Time time={shift.startTime} />
              </div>
              <div style={{ width: 100, textAlign: "center" }}>
                <Time time={shift.endTime} />
              </div>
              <div style={{ width: 100, textAlign: "center" }}>
                {shift.hours}
              </div>
              <div style={{ width: 150, minWidth: 150 }}>
                <Staff id={shift.recordedBy} />
              </div>
              <FormatDateTime value={shift.updatedAt} />
              <div style={{ width: 100 }}></div>

              <DeleteButton
                route="/shifts"
                id={shift._id}
                success={() => shifts.remove(shift)}
                size={30}
              />
            </div>
          ))}
      </div>
    </div>
  );
}

function groupForeign(shifts) {
  let grouped = []
  for (let shift of shifts) {
    let group = grouped.find(g => g.teacher === shift.teacher)
    if (!group) {
      group = {
        teacher: shift.teacher, shifts: []
        
      }
      group.shifts.push(shift)
      grouped.push(group)
    } else {
        group.shifts.push(shift)
    }

    
 
  }
  for (let g of grouped) {
    g.shifts.sort((a,b) => (a.startTime > b.startTime) ? 1 : ((b.startTime > a.startTime) ? -1 : 0))
  }
  return grouped
}
