import React, { useContext, useState } from "react";
import "./classview.css";
import { useNavigate } from "react-router-dom";
import Staff from "../../../components/Staff";
import { StudentsContext, UserContext } from "../../../../../Context";
import Time from "../../../components/Time";
import { ClickAwayListener } from "@mui/material";
import mut from "../../../hooks/mut";
import replaceById from "../../../hooks/replaceById";
import ChangeForeignTeacherDialog from "./ChangeForeignTeacherDialog";
import Icon from "../../../Icon";
import NewForeignTeacherDialog from "./NewForeignTeacherDialog";
import EditClass from "./EditClass";
import DialogHT from "../../../components/DialogHT";
import Lang from "../../../components/Lang";
import { addDays } from "date-fns";



export default function ClassView(props) {
  const { htclass, teacherFilter, search,setClasses, day, week,tests } = props;
  let { students } = useContext(StudentsContext);
  const {user} = useContext(UserContext)
  const navigate = useNavigate();
  const [openFT, setOpenFT] = useState(false);
  const [openNewFT, setOpenNewFT] = useState(false);
  const [openEditClass, setOpenEditClass] = useState(false);
  const [openUnitLesson, setOpenUnitLesson] = useState(false);
  const [openAddTest, setOpenAddTest] = useState(false);
  const tDate = addDays(week, day === 0 ? 6 : day - 1).tDate();
  let classDay = props.htclass.classDays.find((cd) => cd.day === props.day);
  let numberOfStudents = getNumberOfStudents(htclass, classDay, students);
  let searchHit = false;
  let filter = props.search;
  if (teacherFilter !== "") filter = teacherFilter;
  
  if (search !== "") {
    if (htclass.name.toUpperCase().includes(search.toUpperCase()))
      searchHit = true;
  }
  if (teacherFilter !== "") {
    if (htclass.teacher === teacherFilter) searchHit = true;
  
      for (let otherTeacher of classDay.otherTeachers) {
        if(teacherFilter===otherTeacher.teacher) searchHit=true
  
    }
  }
let test = tests.find((t) => t.htClass === htclass._id && t.tDate === tDate);


  let frameHeight = 74;
  if (classDay.otherTeachers.length > 1) {
    frameHeight += (classDay.otherTeachers.length - 1) * 24;
  }



  // context menu

  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
  });
  const handleContextMenu = (event) => {
     if (user.roles.includesOne(["Manager","Head Teacher"])) {
       event.preventDefault();

       setContextMenu({ visible: true, x: event.clientX, y: event.clientY });
     }
  };


  const handleCustomContextAction = async (action) => {
  
    setContextMenu({ visible: false, x: 0, y: 0 });
    if (action === "removeForeignTeacher") {
      let newHtClass = await mut("post", `/classes/${htclass._id}/${week.tDate()}/remove-foreign-teacher`, { classDay: classDay._id });
      setClasses((prev)=> replaceById(setClasses,newHtClass))
    }
     if (action === "changeForeignTeacher") {
     setOpenFT(true)
    }
    if (action === "newForeignTeacher") {
      setOpenNewFT(true);
    }
     if (action === "editClass") {
       setOpenEditClass(true);
    }
  /*   if(action ==="editUnitLesson"){
      setOpenUnitLesson(true)
    } */
     if (action === "addTest") {
       setOpenAddTest(true);
    }
    if (action === "removeTest") {
      let res = await mut("delete", `/tests/${test._id}`)
      tests.remove(res)
      
    }
  };

  const handleClickAway = () => {
    setContextMenu({ visible: false, x: 0, y: 0 });
  };





 



  return (
    <div onContextMenu={handleContextMenu}>
      <div
        className={
          !searchHit && filter !== ""
            ? "frameDisabled"
            : htclass.status === "Coming"
            ? "class-coming"
            : (htclass.level && htclass.level.toLowerCase()) || "frame"
        }
        onClick={() => navigate(`/class/${htclass._id}`)}
        style={{ height: frameHeight, cursor: "pointer" }}
      >
        <div className={"classtitle"}>{htclass.name}</div>
        <div
          className={"teachersName"}
          variant="subtitle2"
        >
          <Staff id={htclass.teacher} />
        </div>
        <div
          className={"classRoom"}
          variant="subtitle2"
          sx={{ color: "#5df4fc", fontSize: 12, fontWeight: "normal" }}
        >
          {classDay.room ? classDay.room : "No room"}
        </div>
        <TestOrUnit
          test={test}
          htClass={htclass}
        />

        <NumberOfStudents
          numberOfStudents={numberOfStudents}
          htclass={htclass}
        />

        <OtherTeachers classDay={classDay} />
      </div>
      {contextMenu.visible && (
        <ContextMenu
          handleClickAway={handleClickAway}
          handleCustomContextAction={handleCustomContextAction}
          contextMenu={contextMenu}
          htclass={htclass}
          classDay={classDay}
          test={test}
        />
      )}
      <ChangeForeignTeacherDialog
        open={openFT}
        setOpen={setOpenFT}
        htClass={htclass}
        classDay={classDay}
        setClasses={setClasses}
        week={week}
      />
      <NewForeignTeacherDialog
        open={openNewFT}
        setOpen={setOpenNewFT}
        htClass={htclass}
        classDay={classDay}
        setClasses={setClasses}
        week={week}
      />
      <EditClass
        open={openEditClass}
        setOpen={setOpenEditClass}
        htClass={htclass}
        classDay={classDay}
        setClasses={setClasses}
        week={week}
      />
      {/* <DialogHT
        open={openUnitLesson}
        setOpen={setOpenUnitLesson}
        content={[
          {
            name: "currentUnit",
            label: "unit",
            type: "number",
            state: htclass.currentUnit,
          },
          {
            name: "currentLesson",
            label: "lesson",
            type: "number",
            state: htclass.currentLesson,
          },
        ]} 
        url={`/classes/${htclass._id}/update`}
        success={(res) => replaceById(setClasses, res)}
        title="updateUnitAndLesson"
      />*/}
      <DialogHT
        open={openAddTest}
        setOpen={setOpenAddTest}
        title="addATest"
        content={[
          { name: "room", label: "room", multiline: true, rows: 3 },
          {
            name: "unit",
            label: "unit",
            type: "number",
            state: htclass.currentUnit,
          },
          { name: "testLink", label: "linkToTestSheet", notRequired: true },
          {
            name: "powerpointLink",
            label: "linkToPowerpointPresentation",
            notRequired: true,
          },
        ]}
        url={`/tests`}
        http="put"
        extraData={{ htClass: htclass._id, tDate }}
        success={(res) => tests.add(res)}
      />
    </div>
  );
}

function TestOrUnit({ test, htClass }) {
   
  return (
    <div className={"classRoom"}>
      {test ? (
        <div className="class-view-test">Test U{test.unit}</div>
      ) : (
        <div>
          U{htClass.currentUnit || ""} L{htClass.currentLesson || ""}
        </div>
      )}
    </div>
  );
}

function OtherTeachers({classDay}) {
  if(!classDay.otherTeachers || classDay.otherTeachers.length === 0) return <></>
  return ( 
          <div className="other-teacher">
            {classDay.otherTeachers.map((teacher) => (
              <div key={teacher._id}>
                <div>
                  <Staff id={teacher.teacher} />{" "}
                  <Time
                    time={teacher.startTime}
                    short
                  />{" "}
                  -{" "}
                  <Time
                    time={teacher.endTime}
                    short
                  />
                </div>
              </div>
            ))}
          </div>
       )
}

function ContextMenu({handleClickAway, handleCustomContextAction, contextMenu, htclass, classDay,test}) {
  
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div
        className="custom-context-menu"
        style={{
          left: contextMenu.x,
          top: contextMenu.y,
        }}
      >
        <div className="context-title">Update {htclass.name}</div>
        {classDay.otherTeachers.length === 0 && (
          <ContextItem
            onClick={() => handleCustomContextAction("newForeignTeacher")}
            icon="add"
            text="addAForeignTeacher"
          />
        )}
        {classDay.otherTeachers.length > 0 && (
          <div>
            <ContextItem
              onClick={() => handleCustomContextAction("changeForeignTeacher")}
              icon="edit"
              text="changeTheForeignTeacher"
            />
            <ContextItem
              onClick={() => handleCustomContextAction("removeForeignTeacher")}
              icon="delete"
              text="removeTheForeignTeacher"
              color="red"
            />
          </div>
        )}
        <ContextItem
          onClick={() => handleCustomContextAction("editClass")}
          icon="edit"
          text="editThisClass"
        />
       {/*  <ContextItem
          onClick={() => handleCustomContextAction("editUnitLesson")}
          icon="edit"
          text="editUnitAndLesson"
        /> */}
        {!test ? <ContextItem
          onClick={() => handleCustomContextAction("addTest")}
          icon="add"
          text="addATest"
        /> :
          <ContextItem
            onClick={() => handleCustomContextAction("removeTest")}
            icon="delete"
            text="removeTheTest"
          />}
      </div>
    </ClickAwayListener>
  );
}


function ContextItem({ onClick, icon, text, color }) {
  return (
    <div
      className="context-button"
      onClick={onClick}
      style={{color : color || "black"}}
    >
      <div>
        <Icon name={icon} />
      </div>
      <div>
        <Lang w={text} />
      </div>
    </div>
  );
}


function getNumberOfStudents(htClass, classDay, students) {
  let number = 0;
  for (let student of htClass.students) {
    let st = students.find((s) => s.id === student);
    if (st && st.doesntStudy && st.doesntStudy.includes(classDay._id)) {
    } else {
      number++;
    }
  }
  return number;
}

function NumberOfStudents({numberOfStudents,htclass}) {
          let color = "#7aff7d";
          if (
            Math.abs(htclass.maxStudents - numberOfStudents) > 1 &&
            Math.abs(htclass.maxStudents - numberOfStudents) < 4
          ) {
            color = "#fffbad";
          }
          if (Math.abs(htclass.maxStudents - numberOfStudents) >= 4) {
            color = "#ffadad";
          }
  return (
    <div
      style={{ color }}
      className={"activeStudents"}
    >
      {numberOfStudents}/{htclass.maxStudents}
    </div>
  );
}