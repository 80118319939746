import {Button,Dialog,DialogActions,DialogContent,DialogTitle} from "@mui/material";
import { useEffect, useState } from "react";
import Lang from "../../components/Lang";
import TimeClock from "../../components/TimeClock";
import AutoCompleteStaff from "../../components/AutoCompleteStaff";
import { differenceInMinutes } from "date-fns";
import mut from "../../hooks/mut";


  const initialState = () => {
    return {
      staffId: "",
      startTime: "",
      endTime: "",
      hours: 0,
    };
  };
export default function NewShiftDialog(props) {
    const { open, setOpen, showDate, shifts } = props;
    const [state, setState] = useState(initialState());
    const [hours, setHours] = useState(0)
    const [disabled, setDisabled] = useState(false)
  
    useEffect(() => {
        const hrs = (differenceInMinutes(new Date("2024-01-01 "+state.endTime), new Date("2024-01-01 "+state.startTime))/60).toFixed(2)
        let dis = false
      if (!state.staffId || !state.startTime || !state.endTime || hrs <= 0.1) dis = true
      console.log(state, hours, dis)
        setDisabled(dis)
        setHours(hrs)
    },[state])


  return (
    <Dialog
      open={open}
      fullWidth
    >
      <DialogTitle>
        <Lang w="addANewShift" />
      </DialogTitle>
      <DialogContent style={{display:"flex", flexDirection:"column", gap:20, paddingTop:10}}>
        <AutoCompleteStaff value={state.staffId} setValue={(staffId)=> setState((prev) => ({ ...prev, staffId }))} label="staffMember" />
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <TimeClock
            value={state.startTime}
            setValue={(startTime) =>
              setState((prev) => ({ ...prev, startTime }))
            }
            label="startTime"
          />
          <TimeClock
            value={state.endTime}
            setValue={(endTime) =>
              setState((prev) => ({ ...prev, endTime }))
            }
            label="finishingTime"
          />
        </div>
              <div style={{
                  display: "flex",
                  gap: 20,
                  justifyContent: "center",
                  fontSize: 24,
                  color: hours > 0 ? "black" : "red"
              }}>
                  <Lang w="hours" />: {hours > 0 ? hours : "incorrect"}</div>
      </DialogContent>
      <DialogActions>
        <Button
                  color="error"
                  onClick={() => {
                      setState(initialState())
                      setOpen(false)
                  }
                  }
        >
          Close
        </Button>
              <Button
                  color="success"
                  disabled={disabled}
                  onClick={(async () => {
                 let res = await mut("post", `/staff/shifts/`, {
                    ...state,
                    location: localStorage.getItem("location"),
                    tDate: showDate.tDate(),hours
                 });
                  shifts.set(res)
                  setState(initialState())
                  setOpen(false)
        })}>Go</Button>
      </DialogActions>
    </Dialog>
  );
}