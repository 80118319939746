import React, { useContext } from "react";
import { MenuItem, TextField } from "@mui/material";

import { CenterContext } from "../../../Context";
import Lang from "./Lang";

/*************  ✨ Codeium Command ⭐  *************/
/**
 * A component to select a location from the center's locations
 * @param {object} props Component props
 * @param {string} props.value The currently selected location
 * @param {function} props.setValue A function to call when the selected location changes
 * @param {bool} props.all If true, "All locations" will be included in the list as the first option
 * @param {object} props Other props are passed to the underlying TextField component
 * @returns A JSX element
 */
/******  418130a8-3da0-41e9-99a8-432eabc87a6f  *******/
export default function LocationChoice(props) {
  const {value,setValue,all=false, fullWidth=false,size="small"} = props
  const { center } = useContext(CenterContext);
  let { locations } = center;
  let style = {
    
  }
  if(!fullWidth) style.width = 200
  if (!center) return <div></div>;

  return (
    <div>
      <TextField
        select
        label={<Lang w="location" />}
        value={value}
        onChange={(e)=>setValue(e.target.value)}
        {...props}
        size={size}
        style={style}
        fullWidth={fullWidth}
      >
        {locations.filter(l => {
          if (all) return true;
          if(!all) return l.name !== "All locations"
        }).map((location) => (
          <MenuItem
            key={location._id}
            value={location._id}
          >
            {location.name}
          </MenuItem>
        ))}
       
      </TextField>
    </div>
  );
}
