import React, { useContext } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import Lang from "./Lang";
import { StaffContext } from "../../../Context";

/**
 * AutoCompleteStaff component renders an autocomplete input for selecting staff members.
 * 
 * @param {object} props - Component props.
 * @param {number} [props.value=1] - The default value for the input.
 * @param {function} props.setValue - Function to update the selected staff member ID.
 * @param {string} props.label - The label for the input field.
 * 
 * @returns {JSX.Element} Autocomplete input field for staff selection.
 */
export default function AutoCompleteStaff({ value = "", setValue, label }) {
  const { staff,findStaff } = useContext(StaffContext);
  const test = [{id:"",label:""},...staff.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).map(s=>({id:s._id, label:s.name}))]
  function isOptionEqualToValue(option, value) { return option.id === value.id; }
  return (
    <Autocomplete
      options={test}
      value={findStaff(value)}
      getOptionLabel={(option) => option.label}
      onChange={(event, newValue) => {
        setValue(newValue ? newValue.id : "");
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={<Lang w={label} />}
        />
      )}
      isOptionEqualToValue={isOptionEqualToValue}
    />
  );
}
