import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import React from "react";
import Lang from "./Lang";


export default function RadioGroupHT({choices,value,setValue, label}) {
console.log(choices)

return (
  <FormControl>
    <FormLabel>
      <Lang w={label} />
    </FormLabel>
    <RadioGroup
      aria-labelledby="demo-radio-buttons-group-label"
      defaultValue={value}
      name="radio-buttons-group"
      row
    >
      {choices.map((c) => (
        <FormControlLabel
          value={c}
          control={<Radio />}
          label={<Lang w={c} />}
          key={c}
          onChange={(e) => setValue(e.target.value)}
        />
      ))}
    </RadioGroup>
  </FormControl>
);
}