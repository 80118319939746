import React, { useState } from "react";
import PaymentsAndCosts from "./components/PaymentsAndCosts";
import DatePicker from "../../components/DatePicker";
import { addDays, format } from "date-fns";
import Lang from "../../components/Lang";
import IconButtonHT from "../../components/IconButtonHT";
import Loading from "../../Loading";


export default function Money(props) {

  const [dt, setDt] = useState(new Date())
  const isoDate = dt.tDate()
  const [loading,setLoading] = useState(true)

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h1>
          <Lang w="money" />
        </h1>

        <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
         
          <DatePicker
            value={dt}
            setValue={setDt}
            align="center"
            label={<Lang w="date" />}
            buttons
            disabled={loading}
          />
         
        </div>
      </div>
   
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <PaymentsAndCosts
            paymentType={0}
            date={isoDate}
            label="cash"
            setLoading={setLoading}
          />
          <PaymentsAndCosts
            paymentType={1}
            date={isoDate}
            label="internet"
            setLoading={setLoading}
          />
        </div>
      
    </div>
  );
}
