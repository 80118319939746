import {Button,Dialog,DialogActions,DialogContent,DialogTitle, TextField} from "@mui/material";
import React, { useState } from "react";
import LocationChoice from "../../../components/LocationChoice";
import replaceById from "../../../hooks/replaceById";
import RoomChoice from "../../../components/RoomChoice";
import StaffChooserContext from "../../../components/StaffChooserContext";
import mut from "../../../hooks/mut";
import TimeChooserList from "../../../components/TimeChooserList";
import RadioGroupHT from "../../../components/RadioGroupHT";
import TimeClock from "../../../components/TimeClock";
import Lang from "../../../components/Lang";

export default function EditClass(props) {
    const { open, setOpen, htClass, classDay, setClasses,week } = props;
    const [htc, setHtc] = useState(htClass)
  const [cd, setCd] = useState(classDay);
  const [future,setFuture] = useState("allFutureClasses")
    
    const update = async () => { 
     
        let updatedClass = {...htc }
  
        updatedClass.classDays = htc.classDays.map(c => c._id === cd._id ? cd : c)
        let result = await mut("post", `/classes/${htClass._id}/${week.tDate()}/${future}`, updatedClass)
        //console.log(result)
        replaceById(setClasses,result)
        setOpen(false)
    }

  return (
    <Dialog
      open={open}
      fullWidth
    >
      <DialogTitle>Edit {htc.name}</DialogTitle>
      <DialogContent
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 10,
          paddingTop: 5,
        }}
      >
        <RadioGroupHT
          choices={["allFutureClasses", "thisClass"]}
          value={future}
          setValue={setFuture}
          label={"changeFor"}
        />
        <LocationChoice
          value={htc.location}
          setValue={(location) => setHtc((prev) => ({ ...prev, location }))}
          fullWidth
          size="normal"
        />
        <RoomChoice
          location={htc.location}
          value={cd.room}
          setValue={(room) => {
            setCd((prev) => ({ ...prev, room }));
          }}
          fullWidth
        />
        <div>
          <StaffChooserContext
            value={htc.teacher}
            setValue={(teacher) => setHtc((prev) => ({ ...prev, teacher }))}
            filter="Teacher"
            label="Teacher"
            fullWidth
          />{" "}
        </div>
        <div style={{ display: "flex", gap: 10 }}>
          <TextField
            value={htc.currentUnit}
            label="Unit"
            type="number"
            onChange={(e) =>
              setHtc((prev) => ({ ...prev, currentUnit: e.target.value }))
            }
            fullWidth
          />
          <TextField
            value={htc.currentLesson}
            label="Lesson"
            type="number"
            onChange={(e) =>
              setHtc((prev) => ({ ...prev, currentLesson: e.target.value }))
            }
            fullWidth
          />{" "}
        </div>{" "}
        <div style={{display:"flex", justifyContent:"space-around"}}>
          <TimeClock
            value={cd.startTime}
            setValue={(startTime) => setCd((prev) => ({ ...prev, startTime }))}
            label="startTime"
          />
          <TimeClock
            value={cd.endTime}
            setValue={(endTime) => setCd((prev) => ({ ...prev, endTime }))}
            label="finishingTime"
          />
        </div>
        {/*  <TimeChooserList
          value={cd.startTime}
          setValue={(startTime) => setCd((prev) => ({ ...prev, startTime }))}
          label="Start time"
          times={["17:15", "17:45","18:00", "18:15", "18:45", "19:30"]}
        />
        <TimeChooserList
          value={cd.endTime}
          setValue={(endTime) => setCd((prev) => ({ ...prev, endTime }))}
          label="Finishing time"
          times={["18:15", "19:15", "19:30", "19:45","20:30", "21:00"]}
        /> */}
      </DialogContent>
      <DialogActions>
        <Button
          color="error"
          onClick={() => setOpen(false)}
        >
          Close
        </Button>
        <Button
          color="success"
          onClick={update}
        >
          Go
        </Button>
      </DialogActions>
    </Dialog>
  );
}