import React from "react";


/**
 * FormatDateYMD
 * 
 * @description A react component that formats a date given as an object with year, month and day as properties.
 * 
 * @param {object} value - the model
 * @param {number} value.year - the year of the date
 * @param {number} value.month - the month of the date
 * @param {number} value.day - the day of the date
 * 
 * @returns {string} the date formatted as dd/mm/yyyy
 */
export default function FormatDateYMD({value}) {

if(!value.year || !value.month || !value.day) return "Incorrect date"

const dt = `${value.day}/${value.month}/${value.year}`
    return (<>{dt}</>)
}