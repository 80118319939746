import React, { createContext, useState } from "react";
import App from "./App";
import { net } from "./database";
export const CenterContext = createContext();
export const StaffContext = createContext();
export const ClassesContext = createContext();
export const StudentsContext = createContext();
export const CustomersContext = createContext();
export const LanguageContext = createContext();
export const UserContext = createContext();
const l = require("./lang.json");


export default function Context(props) {
const [language, setLanguage] = useState(0);
const [user, setUser] = useState(undefined);
const [center, setCenter] = useState(undefined);
const [staffAll, setStaffAll] = useState([]);
const [classes, setClasses] = useState([]);
const [students, setStudents] = useState([]);
const [customers, setCustomers] = useState([]);
    
    React.useEffect(() => {
      try {
        if (user) {
          net.get("/context").then((res) => {
          
            setCenter((prev) => res.center);
            setStaffAll((prev) => res.staff);
            setClasses((prev) => res.classes);
            setStudents((prev) => res.students);
            setCustomers((prev) => res.customers);
          });
        }
      } catch (err) {
        console.error(err);
      }
    }, [user]);

    return (
      <div>
        <LanguageContext.Provider value={{ l, language }}>
          <CenterContext.Provider
            value={{
              center,
              setCenter,
              getLocation: (id) => center.locations.find((l) => l._id === id),
              getLocationName: (id) =>
                center.locations.find((l) => l._id === id).name ||
                "No location",
              locations: center
                ? center.locations.filter((l) => l.name !== "All locations")
                : [],
              getAllLocations: () => center.locations.find(l => l.name === "All locations"),
           
              setSurveyTags:(surveyTags)=>setCenter(prev=>({...prev, surveyTags}))
            }}
          >
            <StaffContext.Provider
              value={{
                staff: staffAll.filter((s) => s.status === 0),
                staffAll,
                setStaffAll,
                findStaff: (_id) => staffAll.find((s) => s._id === _id),
                findStaffName: (_id) => {
                  let staff = staffAll.find((s) => s._id === _id);
                  if (staff && staff.name) return staff.name;
                  return "No name";
                },
                getStaffRole: (role) =>
                  staffAll.filter(
                    (s) => s.roles.includes(role) && s.status === 0
                  ),
                updateStaff: (_id, field, value) => {
                  setStaffAll(prev=>prev.map(p => p._id!==_id ? p : {...p,[field]:value}))
                }
              }}
            >
              <ClassesContext.Provider
                value={{
                  classes,
                  setClasses,
                  findClass: (id) => classes.find((c) => c._id === id),
                  findClassName: (id) => {
                    let htClass = classes.find((c) => c._id === id);
                    if (htClass) return htClass.name;
                    return "Can't find this class";
                  },
                  findClasses: (arrayOfId) =>
                    classes.filter((c) => arrayOfId.includes(c._id)),
                  addClass: (cls) => setClasses((prev) => [...prev, cls]),
                  className: (id) => classes.find((c) => c._id === id).name,
                  activeClasses: () =>
                    classes.filter((c) =>
                      ["Active", "Coming"].includes(c.status)
                    ),
                  active: classes.filter((c) =>
                      ["Active", "Coming"].includes(c.status)
                    ),
                }}
              >
                <StudentsContext.Provider
                  value={{
                    students,
                    setStudents,
                    activeStudents: students.filter((s) =>
                      ["New", "Active"].includes(s.status)
                    ),
                    studentsName: (id) =>
                      students.find((s) => s._id === id).name,
                    findStudent: (id) => students.find((s) => s._id === id),
                    addStudent: (cls) => setStudents((prev) => [...prev, cls]),
                    studentsByStatus: [
                      ...students.filter((s) =>
                        ["New", "Active"].includes(s.status)
                      ),
                      ...students.filter((s) => ["Pending"].includes(s.status)),
                      ...students.filter((s) =>
                        ["Lead", "Stopped"].includes(s.status)
                      ),
                      ...students.filter((s) => ["HotLead"].includes(s.status)),
                    ],
                  }}
                >
                  <CustomersContext.Provider
                    value={{
                      customers,
                      setCustomers,
                      findCustomer: (id) => customers.find((s) => s._id === id),
                      addCustomer: (cls) =>
                        setCustomers((prev) => [...prev, cls]),
                    }}
                  >
                    <UserContext.Provider
                      value={{
                        user,
                        setUser,
                      }}
                    >
                      <App
                        user={user}
                        setUser={setUser}
                        language={language}
                        setLanguage={setLanguage}
                        center={center}
                        setCenter={setCenter}
                        staffAll={staffAll}
                        l={l}
                      />{" "}
                    </UserContext.Provider>
                  </CustomersContext.Provider>
                </StudentsContext.Provider>
              </ClassesContext.Provider>
            </StaffContext.Provider>
          </CenterContext.Provider>
        </LanguageContext.Provider>
      </div>
    );
}