import React, { useContext, useState } from "react";
import { net } from "../../../../database";
import Loading from "../../Loading";
import Select from "../../components/Select";
import Search from "../../components/Search";
import { CenterContext, StaffContext } from "../../../../Context";
import SelectFromList from "../../components/SelectFromList";
import HtTabs from "../../components/HtTabs";
import Classes from "./components/Classes";
import AddButton from "../../components/AddButton";
import NewClassDialog2 from "./NewClassDialog2";
import WeekChooser from "../../components/WeekChooser";
import { startOfWeek } from "date-fns";
import useDBO from "../../hooks/useDBO";



export default function TimeTable(props) {
  let { center } = useContext(CenterContext);
/*   const [classes, setClasses] = useState(undefined); */
  const {staffAll} = useContext(StaffContext)
  const [search, setSearch] = useState("");
  const [teacherFilter, setTeacherFilter] = useState("");
  const [status, setStatus] = useState("Active");
  const [open, setOpen] = useState(false);
  const [week, setWeek] = useState(startOfWeek(new Date(), { weekStartsOn: 1 }))
  const tests = useDBO(`/tests/${week.tDate()}`)
  const classes = useDBO(status === "No foreign teacher" ? "/classes-with-no-foreign-teacher" : `/htclasses/${status}/${week.tDate()}`)
  console.log(classes)
/* 
  React.useEffect(() => {
    if (status !== "Free time") {
      net.get(`/htclasses/${status}`).then((response) => {
        setClasses((prev) => response);
      });
     
    }
    if (status === "No foreign teacher") {
      net.get("/classes-with-no-foreign-teacher").then((response) => {
        setClasses((prev) => response);
      });
    }
  }, [status]); */

  if (classes.loading) return <Loading />;

  let locations = center.locations.filter(l=> classes.find(c=>c.location===l._id))

  let mainTeachers = (classes.map(c => c.teacher))

  let extraTeachers = getOtherTeachers(classes)

  let teachers = [...new Set(mainTeachers.concat(extraTeachers))]

  let labels = locations.map(l=>l.name + ` (${classes.filter(c=>c.location===l._id).length})`)

  let components = [...locations.map(l => <Classes location={l} classes={classes.get} teacherFilter={teacherFilter} search={search} status={status} setClasses={classes.set} week={week} tests={tests} />)]



  
  return (
    <div>

        <HtTabs
          labels={labels}
          components={components}
          localStorage="location"
          nextToTabs={
            <div
              style={{ alignItems: "center", display: "flex", paddingTop: 3 }}
            >
              <WeekChooser value={week} setValue={setWeek} /* style={{marginBottom:12}} */ />
              <div style={{ fontSize: 11, marginRight: 10, width: 100 }}>
                <div className="beginner-legend">Beginner</div>
                <div className="intermediate-legend">Intermediate</div>
                <div className="advanced-legend">Advanced</div>
              </div>
              <Select
                items={[...center.classTypes, "Free time","No foreign teacher"]}
                value={status}
                setValue={setStatus}
                size="small"
                sx={{ mr: 2, width: 200 }}
                label="Status"
              />
              <SelectFromList
                value={teacherFilter}
                setValue={setTeacherFilter}
                choices={staffAll.filter((s) => teachers.includes(s._id))}
                label="Teachers"
                style={{ width: 200 }}
                size="small"
                filter="Teacher"
                none
              />
              <Search
                search={search}
                setSearch={setSearch}
                size="small"
                sx={{ ml: 2 }}
              />
              <AddButton
                action={() => setOpen(true)}
                tooltip="New Class"
              />
              <NewClassDialog2
                open={open}
                setOpen={setOpen}
              />
            </div>
          }
        />

    </div>
  );
}



function getOtherTeachers(classes) {
  let ot = []
  for (let htClass of classes.get) {
    for (let cd of htClass.classDays) {
      for (let otherTeacher of cd.otherTeachers) {
        if(!ot.includes(otherTeacher.teacher)) ot.push(otherTeacher.teacher)
      }
    }
  }
return ot
}