import React, { useContext, useState } from "react";
import "./absent.css";
import { net } from "../../../../database";
import Loading from "../../Loading";
import { Button, Grid, IconButton, TextField} from "@mui/material";

import { Link } from "react-router-dom";
import { formatPhone } from "../../../../functions";
import { socket } from "../../../../App";
import AddMakeUpClassDialog from "./AddMakeUpClassDialog";
import { format } from "date-fns";
import CancelIcon from "@mui/icons-material/Cancel";
import Lang from "../../components/Lang";
import HtClass from "../../components/HtClass";
import SLink from "../../components/SLink";
import HtTabs from "../../components/HtTabs";
import { CenterContext, ClassesContext } from "../../../../Context";
import useDBO from "../../hooks/useDBO";
import Text from "../../components/Text";
import DatePicker from "../../components/DatePicker";

export default function Absent() {

  const { center } = useContext(CenterContext)
  const [dt,setDt] = useState(new Date())
  const absences = useDBO(`/absent/${dt.tDate()}`);

  const labels = center.locations.filter(l=>l.name!=="All locations").map(l => l.name)
  const components = center.locations.filter(l=>l.name!=="All locations").map(l => <DrawAbsentLocation absences={absences} location={l._id} />)
 
  return (
    <div style={{ padding: 10 }}>
      <div className="dflex-sb">
        <h1>
          <Lang w="absentStudents" />
        </h1>
      </div>

      <HtTabs
        labels={labels}
        components={components}
        localStorage="location"
        nextToTabs={
          <DatePicker
            value={dt}
            setValue={setDt}
            buttons
            disabled={absences.loading}
            align="center"
          />
        }
      />
    </div>
  );
}

function DrawAbsentLocation({  absences, location}) {


 
  const { findClass } = useContext(ClassesContext)
    if (absences.loading) return <Loading />;
  let filClasses = []
   let htClasses = [...new Set(absences.map((a) => a.htClass))];
  for (let htc of htClasses) {
    let c = findClass(htc)
    if(c.location===location) filClasses.push(htc)
  }

  return (
    <div>
      <Grid
        container
        className="absent-table-labels"
      >
        <Grid
          item
          xs={3}
        >
          <Lang w="name" />
        </Grid>
        <Grid
          item
          xs={1}
        >
          <Lang w="type" />
        </Grid>
        <Grid
          item
          xs={3}
        >
          <Lang w="reason" />
        </Grid>
      </Grid>
      {filClasses.map((id) => (
        <div>
          <h3>
            <HtClass
              htClass={id}
              link
            />
          </h3>

          {absences
            .filter((a) => a.htClass === id)
            .map((absence) => (
              <Grid
                container
                alignItems="center"
                sx={{ mb: 1 }}
              >
                <Grid
                  item
                  xs={3}
                >
                  <SLink
                    id={absence.student}
                    showPhone
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  style={{
                    color: absence.attendance === 2 ? "#ffae00" : "#ff0055",
                  }}
                >
                  {absence.attendance === 2 ? "P" : "O"}
                </Grid>
                <Grid
                  item
                  xs={3}
                >
                  {absence.attendance < 3 ? (
                    absence.absentReason
                  ) : (
                    /*  <TextField
                      value={absence.absentReason ? absence.absentReason : ""}
                      size="small"
                      label="Reason"
                      onChange={(e) => addAbsentReason(e.target.value, absence)}
                        onBlur={() => updateAbsentReason(absence)}
                        fullWidth
                    /> */
                    <Text
                      value={absence.absentReason || ""}
                      type="text"
                      setValue={(absentReason) =>
                        absences.update({ ...absence, absentReason })
                      }
                      url={`/attendance/${absence._id}`}
                      field="absentReason"
                      size="small"
                    />
                  )}
                </Grid>
              </Grid>
            ))}
        </div>
      ))}
    </div>
  );
}