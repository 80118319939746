import React, { useEffect, useState } from "react";

import Loading from "../../components/Loading";
import Error from "../../components/Error";
import Staff from "../../components/Staff";
import Loca from "../../components/Loca";
import "./ftTimetable.css";
import useDBO from "../../hooks/useDBO";
import History from "./History";
import { net } from "../../../../database";
import { addDays, format, startOfWeek } from "date-fns";

export default function FtTimetable(props) {

  const htClasses = useDBO(`/htclasses?status=Active`)
  const [history, setHistory] = useState("")
  const [historyData, setHistoryData] = useState(undefined)
  
  useEffect(() => {
    if (history) {
      net.get(`/ft-timetable-history/${history}`).then(res => {
        setHistoryData(res)
      })
    }
  },[history])

if (htClasses.loading) return <Loading />;
if (htClasses.error) return <Error />;

let ftTeachers = []

  for (let c of htClasses.get) {
    for (let cd of c.classDays) {
      for (let t of cd.otherTeachers) {
        let teacher = ftTeachers.find(f => f._id === t.teacher)
        if (!teacher) {
          ftTeachers.push({ _id: t.teacher, shifts: [{ startTime: t.startTime, endTime: t.endTime, location: c.location, room: cd.room, day: cd.day,name: c.name }] })
        } else {
          teacher.shifts.push({ startTime: t.startTime, endTime: t.endTime, location: c.location, room: cd.room,day: cd.day,name:c.name })
        }
      }
    }
  }

  let st = history ? new Date(history) : startOfWeek(new Date(), { weekStartsOn: 1 });

  if(new Date().getDay() ===6 || new Date().getDay() ===0) st = addDays(st,7)

  const startDate=`${format(st,"dd-MM-yyyy")} - ${format(addDays(st,7),"dd-MM-yyyy")}`

  console.log(historyData)
  if(history && historyData) return (
    <div>
      <History
        value={history}
        setValue={setHistory}
      />
      {historyData.map((t) => (
        <div>
          <DrawTeacherTimetable
            teacher={t}
            startDate={startDate}
          />
        </div>
      ))}
    </div>
  );

  return (
    <div>
      <History
        value={history}
        setValue={setHistory}
      />
      {ftTeachers.map((t) => (
        <div>
          <DrawTeacherTimetable teacher={t} startDate={startDate} />
        </div>
      ))}
    </div>
  );
}

function DrawTeacherTimetable({ teacher, startDate }) {
  
  let times = [...new Set([...teacher.shifts.map((s) => s.startTime)])]
  let days = [{day:10, name:""},{ day: 1,name:"Mon" }, { day: 2,name:"Tue" }, { day: 3,name:"Wed" }, { day: 4,name:"Thu" }, { day: 5,name:"Fri" }, { day: 6,name:"Sat" }, { day: 0,name:"Sun" }]
  times = times.sort()

  return (
    <div>
      <div className="teachers-name">
        <Staff id={teacher._id} /> <div style={{fontSize:12}}>{startDate}</div>
      </div>
      <table className="shift-table">
        <tr>
          {days.map((d) => (
            <th>{d.name}</th>
          ))}
        </tr>
        {times.map((t) => (
          <tr>
            <DrawTime
              time={t}
              days={days}
              shifts={teacher.shifts.filter((s) => s.startTime === t)}
            />
          </tr>
        ))}
      </table>
    </div>
  );
}

function DrawTime({ time, days, shifts }) {
  
  return <><td className="shift-cell">{time}</td>{days.slice(1,7).map(d => <td className="shift-cell"><DrawClass shift={shifts.find(s => s.day === d.day)}/></td>)}</>
}


function DrawClass({ shift }) {
  if(!shift) return <div></div>
  return (
    <div>
      <div className="name-of-class">{shift.name}</div>
      <div className="class-details">
        {shift.startTime} - {shift.endTime}
      </div>
      <div className="class-details">
        <Loca id={shift.location} />
      </div>
      <div className="class-details">{shift.room}</div>
    </div>
  );
}