import { useEffect, useState } from "react";
import { apiKey } from "../../../App";
import axios from "axios";

  /**
   * A hook to get an array of data from the database and perform crud operations
   * @param {string} url the url of the api endpoint
   * @param {string} refresh optional how often to update the data in seconds
   * @returns {object} an object containing the array of data, a function to update the array, an error if any, a boolean indicating if the data is being loaded, and functions to add, remove, update and filter the array
   * @returns {array} get: The array of data
   * @returns {function} set: a function to update the state array
   * @returns {object} error: the error if any
   * @returns {boolean} loading: a boolean to indicate if the data is being loaded
   * @returns {function} add(item): a function to add a new item to the array
   * @returns {function} remove(item): a function to remove an item from the array
   * @returns {function} update(item): a function to update an item in the array
   * @returns {function} updateById(id,item): a function to update an item in the array
   * @returns {function} filter(function): filter the array, non destructive, returns a new array 
   * @returns {function} sortBy(field, direction=1): sort the array by a field in the objects
   * @returns {function} sort(function): sort the array
   * @returns {function} reduce(field): reduce the array by a field in the objects
   * @returns {function} uniqueSet(field): returns an array of unique values in the array
   * @returns {function} refresh(): refresh the data from the database
  */
export default function useDBO(url, reLoad=0) {
  const [get, set] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(true);
 
  useEffect(() => {
  
    getData(url)
       
    if (reLoad<1) return
    
        const interval = setInterval(() => {
          getData(url,false);
        }, reLoad*1000);
        
    return () => clearInterval(interval);
      
  }, [url]);


  async function getData(url, showLoading = true) {
 try {
   if(showLoading) setLoading(true);
   const response = await axios({
     method: "get",
     url: `/api${url}`,
     headers: {
       "Content-Type": "application/json",
       Authorization: "Bearer " + apiKey,
     },
   });
   set(response.data);
 } catch (err) {
   setError(err);
 } finally {
   if (showLoading) setLoading(false);
 }
  }

  /**
   * A function to add an item to the array
   * @param {object} item the item to add
   */
  function add(item) {
    set([...get, item]);
  }

  function log() {
    console.log(get);
  }

  async function refresh() {
    await getData(url, false);
  }

  /**
   * A function to update an item in the array.
   * @param {object} item the new data to update in the item
   */
  function update(item) {
    set(get.map((i) => (i._id === item._id ? {...i, ...item} : i)));
  }
    
  /**
   * A function to update an item in the array by its _id
   * @param {string} id the _id of the item to update
   * @param {object} item the new item to update
   */
    function updateById(id,item) {
      set(get.map((i) => (i._id === id ? {...i, ...item} : i)));
    }

  /**
   * A function to remove an item from the array
   * @param {string} id the _id of the item to remove
   */
  function remove(id) {
 
    if (typeof id === "object") {
       set(get.filter((item) => item._id !== id._id));
      return;
    } 
    set(get.filter((item) => item._id !== id));
  }

  /**
   * A function to filter the array
   * @param {function} filter a function that takes an item and returns true if it should be included in the filtered array
   */
  function filter(filter) {
    return get.filter(filter);
  }

  function sort(sort) {
    return get.sort(sort);
  }

  function sortBy(field, direction = 1) {
    return get.sort((a, b) =>
      a[field] > b[field] ? direction : b[field] > a[field] ? 0-direction : 0
    );
  }
  
  function reduce(field) {
    return get.reduce((acc, item) => acc + item[field], 0);
  }

  function uniqueSet(field) {
    return [...new Set(get.map(a => a[field]))]
  }

  function map(fn) {
    return get.map(fn);
  }

  function find(filter) {
    if (get) return get.find(filter);
    return undefined
  }
  
  return {
    get,
    set,
    error,
    loading,
    add,
    remove,
    update,
    updateById,
    filter,
    log,
    sort,
    sortBy,
    refresh,
    reduce,
    uniqueSet,
    map,
    find
  };
}
