import React from "react";
import HtClass from "../../../components/HtClass";


export default function ClassHistory({student}) {

    const { classHistory } = student

console.log(classHistory)

    return (<div>
        <table>
            <tr><th>Class</th><th>Start</th><th>End</th><th>Reason</th></tr>
            {classHistory.map((ch) => (
                <tr>
                    <td><HtClass htClass={ch.htClass} /></td>
                    <td>{new Date(ch.startDate).fDate()}</td>
                    <td>{ch.endDate ? new Date(ch.endDate).fDate() : "No end date"}</td>
                    <td>{ch.reason}</td>
                </tr>
            ))}
    </table>
</div>)
}