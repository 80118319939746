import React from "react";
import Payments from "./Payments";
import Costs from "./Costs";
import Lang from "../../../components/Lang";
import CashWithdrawals from "./CashWithdrawals";


export default function PaymentsAndCosts({paymentType,date, label,setLoading}) {


    return (
      <div style={{ width: "50%", minWidth: "50%", padding: 10 }}>
        <div className="money-label">
          <Lang w={label} />
        </div>
        <Payments
          paymentType={paymentType}
          date={date}
          setLoading={setLoading}
        />
        <br />
        <Costs
          costType={paymentType}
          date={date}
          setLoading={setLoading}
        />
        {paymentType === 0 && (
                <div>
                <br />
                <CashWithdrawals date={date} />
                </div>
        )}
      </div>
    );
}