import React from "react";
import "./money.css";
import useDBO from "../../../hooks/useDBO";
import Loading from "../../../components/Loading";
import Error from "../../../components/Error";
import VND from "../../../components/VND";
import SLink from "../../../components/SLink";
import Lang from "../../../components/Lang";
import ScreenReceipt from "../../student/components/ScreenReceipt";


export default function Payments({paymentType,date,setLoading}) {


const payments = useDBO(`/payments?type=${paymentType}&tDate=${date}`)

setLoading(payments.loading)
if (payments.loading) return <Loading />;
if (payments.error) return <Error />;


    
    return (
      <div className="money-outline">
        <div className="money-subtitle">
          <Lang w="payments" />
        </div>
        {payments.map((p, i) => (
          <DrawPayment
            payment={p}
            i={i}
            key={payments._id}
          />
        ))}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 5,
            fontWeight: "bold",
          }}
        >
          <div>
            <Lang w="total" />:
          </div>
          <div>
            <VND value={payments.reduce("amount")} />
          </div>
        </div>
      </div>
    );
}

function DrawPayment({ payment }) {
  
 /*  const [receipt, setReceipt] = React.useState(true); */
  
    console.log(payment)
    return (
      <div style={{display:"flex", justifyContent: "space-between", alignItems:"top", borderBottom: "1px #ccc solid"}}>
        <div >
          {payment.students.map((s) => (
            <SLink
              id={s}
                  showClasses
                  key={s._id}
            />
          ))}
        </div>
        <VND value={payment.amount}/>
       {/*  <ScreenReceipt receipt={payment.receipt} open={receipt} setOpen={setReceipt}/> */}
      </div>
    );
}